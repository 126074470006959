import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components/macro';
import Logo from 'src/components/Logo';
import { basePStyles } from 'src/components/Styled';

import * as colors from 'src/constants/colors';
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  YELP_URL,
} from 'src/constants/urls';
import {
  TABLET_SCREEN_WIDTH_PX,
  MOBILE_CONTENT_HZ_PADDING_PX,
} from 'src/constants/layout';

const baseLinkStyles = css`
  ${basePStyles};
  font-size: 13px;
  font-weight: 300;
  color: ${colors.white};
  opacity: 0.6;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
    font-size: 14px;
  }
`;

const Styled = {
  Footer: styled.footer`
    color: white;
    background: ${colors.darkBlue};
    padding: 2rem;
    display: flex;
    align-items: center;
  `,
  LogoWithText: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 0 0.5rem;
  `,
  LogoText: styled.div`
    ${basePStyles};
    text-transform: uppercase;
    text-align: center;
    opacity: 0.6;
    font-weight: lighter;
    font-size: 9px;
    white-space: nowrap;
    margin-top: 0.5rem;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      font-size: 18px;
    }
  `,
  LinksColumn: styled.div`
    display: flex;
    flex-direction: column;
    text-align: ${props => (props.alignRight ? 'right' : 'left')};
  `,
  Link: styled(Link)`
    ${baseLinkStyles};
  `,
  ExternalLink: styled.a`
    ${baseLinkStyles};
  `,
};

const Footer: React.FC = () => (
  <Styled.Footer>
    <Styled.LinksColumn>
      <Styled.Link to="/">Home</Styled.Link>
      <Styled.Link to="/about">About Us</Styled.Link>
      <Styled.Link to="/contact">Contact Us</Styled.Link>
      <Styled.Link to="/products">Products</Styled.Link>
    </Styled.LinksColumn>
    <Styled.LogoWithText>
      <Logo color="rgba(255, 255, 255, 0.6)" />
      <Styled.LogoText>ATB Material Handling</Styled.LogoText>
    </Styled.LogoWithText>
    <Styled.LinksColumn alignRight>
      <Styled.ExternalLink href={FACEBOOK_URL}>Facebook</Styled.ExternalLink>
      <Styled.ExternalLink href={INSTAGRAM_URL}>Instagram</Styled.ExternalLink>
      <Styled.ExternalLink href={LINKEDIN_URL}>LinkedIn</Styled.ExternalLink>
      <Styled.ExternalLink href={YELP_URL}>Yelp</Styled.ExternalLink>
    </Styled.LinksColumn>
  </Styled.Footer>
);

export default Footer;
