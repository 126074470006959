import { Link, GatsbyLinkProps } from 'gatsby';
import React, { useState } from 'react';
import { omit } from 'ramda';
import styled from 'styled-components/macro';
import AnimateHeight from 'react-animate-height';
import Logo from 'src/components/Logo';
import CustomGatsbyLink from 'src/components/CustomGatsbyLink';
import { HamburgerMenuIcon, Chevron } from 'src/components/icons';
import { basePStyles } from 'src/components/Styled';

import * as colors from 'src/constants/colors';
import {
  DESKTOP_SCREEN_WIDTH_PX,
  TABLET_SCREEN_WIDTH_PX,
  TABLET_CONTENT_HZ_PADDING_PX,
  MOBILE_CONTENT_HZ_PADDING_PX,
} from 'src/constants/layout';

const EXPANDED_MOBILE_NAV_BG_COLOR = 'rgba(0, 0, 0, 0.5)';

const Styled = {
  MobileNavbar: styled.div`
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${MOBILE_CONTENT_HZ_PADDING_PX}px ${MOBILE_CONTENT_HZ_PADDING_PX}px;
    position: absolute;
    z-index: 1000;
    width: 100%;
    box-sizing: border-box;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      padding: ${TABLET_CONTENT_HZ_PADDING_PX}px ${TABLET_CONTENT_HZ_PADDING_PX}px;
    }

    @media only screen and (min-width: ${DESKTOP_SCREEN_WIDTH_PX}px) {
      display: none;
    }
  `,
  Navbar: styled.div`
    background: transparent;
    justify-content: space-between;
    align-items: center;
    padding: 2.75rem 3rem 1rem 3rem;
    position: absolute;
    z-index: 1000;
    width: 100%;
    box-sizing: border-box;
    display: none;

    @media only screen and (min-width: ${DESKTOP_SCREEN_WIDTH_PX}px) {
      display: flex;
    }
  `,
  Links: styled.div`
    display: flex;
  `,
  NavPageLink: styled(props => (
    <CustomGatsbyLink
      {...omit(
        ['isActive', 'lightText', 'isMobile'],
        props as GatsbyLinkProps<Record<string, unknown>>,
      )}
    />
  ))`
    text-decoration: none;
    color: ${props => (props.lightText ? colors.white : colors.black)};
    padding: 0.25rem 1.5rem;
    border: 2px solid
      ${props =>
        props.isActive
          ? props.lightText
            ? colors.white
            : colors.black
          : 'transparent'};
    font-family: Rubik;
    font-style: normal;
    font-weight: lighter;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.2%;
    text-transform: capitalize;
    ${props =>
      props.isMobile &&
      `
    display: block;
    text-align: center;
    `}
  `,
  NavHomeLink: styled(props => (
    <CustomGatsbyLink
      {...omit(['lightText'], props as GatsbyLinkProps<Record<string, unknown>>)}
    />
  ))`
    text-decoration: none;
    color: ${props => (props.lightText ? colors.white : colors.black)};
    font-family: Foco, sans-serif; // TODO: buy and configure Foco font
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
  `,
  NavLogoText: styled.span`
    ${basePStyles};
    margin-left: 1rem;
    text-transform: uppercase;
    font-weight: lighter;
    font-size: 18px;
    white-space: nowrap;
  `,
  ToggleDropdownMenuButton: styled.button`
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;
    background: none;
  `,
  DropdownMenu: styled(AnimateHeight)`
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    background: ${EXPANDED_MOBILE_NAV_BG_COLOR};
  `,
};

const NAV_LINKS = [
  { to: '/', label: 'Home', value: 'home' },
  { to: '/about', label: 'About Us', value: 'about' },
  { to: '/products', label: 'Products', value: 'products' },
  { to: '/contact', label: 'Contact Us', value: 'contact' },
];

interface Props {
  page: string;
}

const Navbar: React.FC<Props> = ({ page }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const navLinks = NAV_LINKS.map(navLink => ({
    ...navLink,
    isActive: page === navLink.value,
    lightText: page === 'home',
  }));
  const logo = (
    <Logo color={page === 'home' ? colors.white : colors.black} size={40} />
  );
  return (
    <>
      <Styled.MobileNavbar isExpanded={isExpanded}>
        <Styled.Links>
          <Styled.NavHomeLink to="/" lightText={page === 'home'}>
            {logo}
          </Styled.NavHomeLink>
        </Styled.Links>
        <Styled.ToggleDropdownMenuButton
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded ? (
            <Chevron orientation="down" size={24} />
          ) : (
            <HamburgerMenuIcon size={24} />
          )}
        </Styled.ToggleDropdownMenuButton>
        <Styled.DropdownMenu duration={300} height={isExpanded ? 'auto' : 0}>
          {navLinks.map(navLink => (
            <Styled.NavPageLink
              key={navLink.to}
              to={navLink.to}
              isActive={navLink.isActive}
              lightText
              isMobile
            >
              {navLink.label}
            </Styled.NavPageLink>
          ))}
        </Styled.DropdownMenu>
      </Styled.MobileNavbar>

      <Styled.Navbar>
        <Styled.Links>
          <Styled.NavHomeLink to="/" lightText={page === 'home'}>
            {logo}
            <Styled.NavLogoText>ATB Material Handling</Styled.NavLogoText>
          </Styled.NavHomeLink>
        </Styled.Links>
        <Styled.Links>
          {navLinks.map(navLink => (
            <Styled.NavPageLink
              key={navLink.to}
              to={navLink.to}
              isActive={navLink.isActive}
              lightText={navLink.lightText}
            >
              {navLink.label}
            </Styled.NavPageLink>
          ))}
        </Styled.Links>
      </Styled.Navbar>
    </>
  );
};

export default Navbar;
