import styled from 'styled-components/macro';

const Icon = styled.div`
  ${props => {
    if (!props.orientation) return '';
    switch (props.orientation) {
      case 'up':
        return 'transform: rotate(270deg);';
      case 'left':
        return 'transform: rotate(180deg);';
      case 'down':
        return 'transform: rotate(90deg);';
      case 'right':
        return '';
      default:
        return '';
    }
  }}
  display: inline-block;
  position: relative;
  width: ${props => (props.size ? props.size : 12)}px;
  height: ${props => (props.size ? props.size : 12)}px;
  > svg {
    width: inherit;
    height: inherit;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

export { Icon };
