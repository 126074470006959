import React from 'react';

import * as Styled from './styles';

const Chevron: React.FC<{
  orientation?: 'up' | 'left' | 'down' | 'right';
  size?: number;
}> = ({ orientation, size }) => (
  <Styled.Icon orientation={orientation} size={size}>
    <svg viewBox="0 0 17 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 2L14.6291 18.6704C15.44 19.7408 15.4408 21.2198 14.631 22.291L2 39"
        stroke="black"
        strokeWidth="3.5"
        strokeLinejoin="round"
      />
    </svg>
  </Styled.Icon>
);

export default Chevron;
