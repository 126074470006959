export const darkBlue = '#0D1C3F';
export const richRed = '#972221';
export const lightGray = 'hsl(200, 7%, 92%)';
export const white = '#fff';
export const black = '#000';
export const blueishBlack = '#343434';
export const grayBg1 = '#dedede';
export const grayBg2 = '#eee';
export const grayBg3 = '#f0f0f2';
export const grayBg4 = '#efefef';
export const grayBg5 = '#c4c4c4';
export const darkGray = '#606B74';
export const inputBorderGray = '#acacac';
