import styled, { css } from 'styled-components/macro';
import {
  DESKTOP_SCREEN_WIDTH_PX,
  MAX_CONTENT_WIDTH_PX,
  CONTENT_HZ_PADDING_PX,
  MOBILE_CONTENT_HZ_PADDING_PX,
  TABLET_CONTENT_HZ_PADDING_PX,
  TABLET_SCREEN_WIDTH_PX,
} from 'src/constants/layout';

const H1 = styled.h1`
  font-family: Foco, sans-serif; // TODO: buy and configure Foco font
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 134%;
  letter-spacing: 0.002em;
  margin: 1rem 0 0.75rem 0;
  color: ${props => (props.color ? props.color : 'inherit')};

  @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
    font-size: 42px;
    margin: 1.5rem 0 1rem 0;
  }

  @media only screen and (min-width: ${DESKTOP_SCREEN_WIDTH_PX}px) {
    font-size: 60px;
  }
`;

const H2 = styled.h2`
  font-family: Foco, sans-serif; // TODO: buy and configure Foco font
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 38px;
  color: ${props => (props.color ? props.color : 'inherit')};

  @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
    font-size: 34px;
  }
  @media only screen and (min-width: ${DESKTOP_SCREEN_WIDTH_PX}px) {
    font-size: 38px;
  }
`;

const basePStyles = css`
  font-family: Rubik;
  font-style: normal;
  font-size: 16px;
  line-height: 143%;
  letter-spacing: 0.2%;
`;

const P = styled.p`
  ${basePStyles}
  color: ${props => (props.color ? props.color : 'inherit')};
`;

const HeroSubtext = styled(P)`
  font-weight: lighter;
  font-size: 18px;
  line-height: 26px;
  max-width: 360px;

  @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export { H1, H2, P, HeroSubtext, basePStyles };
