import React from 'react';
import styled from 'styled-components/macro';

import { PhoneIcon } from 'src/components/icons';

import * as colors from 'src/constants/colors';
import { HERO_HEIGHTS_BY_PAGE } from 'src/constants/layout';

const Styled = {
  CallNowWrapper: styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    overflow: visible;
    z-index: 2;
    height: ${props => HERO_HEIGHTS_BY_PAGE[props.page].height};
    min-height: ${props => HERO_HEIGHTS_BY_PAGE[props.page]['min-height']};
    max-height: ${props => HERO_HEIGHTS_BY_PAGE[props.page]['max-height']};
  `,
  CallNowButton: styled.a`
    background: ${colors.richRed};
    border: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    text-decoration: none;
    display: inline-block;
    padding: 6px;
    padding-right: 6px;
    position: absolute;
    right: 0;
    bottom: 4rem;
    transition: padding-right 0.2s;

    &:hover {
      padding-right: 12px;
    }
  `,
};
const CallNowButton: React.FC<{ page: string }> = ({ page }) => (
  <Styled.CallNowWrapper page={page}>
    <Styled.CallNowButton href="tel:3237269424">
      <PhoneIcon size={42} />
    </Styled.CallNowButton>
  </Styled.CallNowWrapper>
);

export default CallNowButton;
