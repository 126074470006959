// Mobile layout constants
export const MOBILE_CONTENT_HZ_PADDING_PX = 24;

// Tablet layout constants
export const TABLET_CONTENT_HZ_PADDING_PX = 40;

// Desktop layout constants
export const MAX_CONTENT_WIDTH_PX = 1400;
export const CONTENT_HZ_PADDING_PX = 80;

// Main layout breakpoints
export const LARGE_MOBILE_SCREEN_WIDTH_PX = 480;
export const TABLET_SCREEN_WIDTH_PX = 620;
export const DESKTOP_SCREEN_WIDTH_PX = 980;

// Hero Section Height constants
export const HERO_HEIGHTS_BY_PAGE = {
  home: {
    height: '100vh',
    'min-height': '500px',
    'max-height': '1600px',
  },
  about: {
    height: '60vh',
    'min-height': '460px',
    'max-height': '700px',
  },
  products: {
    height: '60vh',
    'min-height': '460px',
    'max-height': '700px',
  },
  contact: {
    height: '60vh',
    'min-height': '240px',
    'max-height': '700px',
  },
  '404': {
    height: '80vh',
    'min-height': '320px',
    'max-height': '90vh',
  },
};
