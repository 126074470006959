import React from 'react';
import styled from 'styled-components/macro';

const DEFAULT_LOGO_SIZE = 62;

const Styled = {
  Logo: styled.div`
    width: ${props => `${props.size}px`};
  `,
};

interface Props {
  color?: string;
  size?: number;
}

const Logo: React.FC<Props> = ({ color, size }) => (
  <Styled.Logo size={size || DEFAULT_LOGO_SIZE}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 383.66 308.07"
      height="100%"
      width="100%"
      fill={color || 'none'}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-1"
            d="M133.51,115.26V60.74L92.31,61v9.17H111v71.16h40.79l14.14,31.89H129.12L92.31,233.51H66.55v12.26H34.89v62.3H348.37v-62.3H316.7V233.51H291l-36.8-60.33H217.37l14.13-31.89h40.83V70.13H291V61l-41.19-.22v54.52Zm93.56,88.33,22.72,33.86v8.32H133.51v-8.32l22.72-33.86Z"
          />
          <path
            className="cls-1"
            d="M383.66,0H371.85V26.3h-361V0H0V60.74H383.66ZM30.1,52.55H21.2v-8.9h8.9Zm0-13.28H21.2v-8.9h8.9ZM48.35,52.55h-8.9v-8.9h8.9Zm0-13.28h-8.9v-8.9h8.9Zm18,13.28h-8.9v-8.9h8.9Zm0-13.28h-8.9v-8.9h8.9ZM84.62,52.55h-8.9v-8.9h8.9Zm0-13.28h-8.9v-8.9h8.9Zm21.29,13.28H97v-8.9h8.9Zm0-13.28H97v-8.9h8.9ZM124.2,52.55h-8.9v-8.9h8.9Zm0-13.28h-8.9v-8.9h8.9Zm18,13.28h-8.9v-8.9h8.9Zm0-13.28h-8.9v-8.9h8.9Zm18.25,13.28h-8.85v-8.9h8.85Zm0-13.28h-8.85v-8.9h8.85ZM178.1,52.55h-8.9v-8.9h8.9Zm0-13.28h-8.9v-8.9h8.9Zm18,13.28h-8.9v-8.9h8.9Zm0-13.28h-8.9v-8.9h8.9Zm18.25,13.28h-8.9v-8.9h8.9Zm0-13.28h-8.9v-8.9h8.9Zm21.29,13.28h-8.9v-8.9h8.9Zm0-13.28h-8.9v-8.9h8.9ZM254,52.55h-8.9v-8.9H254Zm0-13.28h-8.9v-8.9H254Zm18,13.28H263v-8.9h8.9Zm0-13.28H263v-8.9h8.9Zm18.29,13.28h-8.9v-8.9h8.9Zm0-13.28h-8.9v-8.9h8.9ZM307.8,52.55h-8.9v-8.9h8.9Zm0-13.28h-8.9v-8.9h8.9Zm18,13.28h-8.9v-8.9h8.9Zm0-13.28h-8.9v-8.9h8.9ZM344,52.55h-8.86v-8.9H344Zm0-13.28h-8.86v-8.9H344Zm21.33,13.28h-8.9v-8.9h8.9Zm0-13.28h-8.9v-8.9h8.9Z"
          />
          <circle className="cls-1" cx="191.64" cy="91.64" r="23.63" />
        </g>
      </g>
    </svg>
  </Styled.Logo>
);

export default Logo;
