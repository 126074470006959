import React from 'react';

import * as colors from 'src/constants/colors';
import * as Styled from './styles';

const YelpLogoIcon: React.FC<{ size?: number; color?: string }> = ({
  size,
  color = colors.darkBlue,
}) => (
  <Styled.Icon size={size}>
    <svg version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={color}
        clipRule="evenodd"
        d="M25.701,0h460.897C500.509,0,512,11.492,512,25.707v460.892   c0,13.91-11.491,25.401-25.401,25.401H25.701C11.492,512,0,500.509,0,486.599V25.707C0,11.492,11.492,0,25.701,0L25.701,0z    M213.805,68.95c-14.509,3.028-29.938,7.26-43.848,12.701c-31.747,12.102-30.538,18.147-19.046,38.107   c14.209,24.192,45.357,75.001,65.922,105.849c6.356,9.373,17.538,26.616,30.848,22.079c13-4.237,10.583-21.475,9.972-32.362   c-1.808-41.43-5.136-87.402-8.463-128.532C247.381,63.809,233.471,65.018,213.805,68.95L213.805,68.95z M178.431,423.095   c15.119,10.882,45.657,21.464,60.476,22.073c16.638,0.91,17.847-8.773,18.146-21.475c0.3-23.582,0.3-46.876,0.3-70.159   c0-9.683,0.61-18.446-9.373-22.384c-11.492-4.227-17.837,0.61-24.492,8.774c-15.729,18.446-31.457,37.192-46.876,55.649   C166.629,407.366,164.821,413.711,178.431,423.095L178.431,423.095z M343.841,180.245c-5.126,2.113-6.635,4.537-9.962,9.074   c-16.039,21.774-32.677,44.453-48.695,66.537c-5.745,7.555,0,28.729,23.894,22.983c24.492-6.056,50.493-12.102,75.295-18.457   c11.202-3.018,17.837-8.464,10.893-25.402c-5.746-14.514-19.656-35.984-31.147-47.176   C358.061,181.759,350.807,177.222,343.841,180.245L343.841,180.245z M115.526,258.873c-3.937,15.429-4.836,45.967-1.519,60.486   c3.028,13.61,10.283,17.237,23.893,13c23.893-7.554,48.695-15.418,72.277-23.282c7.565-2.419,12.102-3.928,14.52-11.192   c6.045-17.537-11.492-22.374-22.684-27.521c-7.254-3.327-41.13-16.633-61.385-24.797   C127.018,240.426,119.753,241.94,115.526,258.873L115.526,258.873z M291.24,339.925c13,22.073,27.199,45.367,41.109,66.531   c6.655,9.983,13.311,16.328,27.53,4.837c7.854-6.646,29.039-29.029,36.894-46.876c8.164-18.447,1.219-21.774-14.51-27.521   c-20.875-7.255-55.04-18.146-64.113-20.865c-9.983-3.327-18.746-6.655-26.021,2.119   C285.494,325.715,286.692,331.761,291.24,339.925L291.24,339.925z"
        fillRule="evenodd"
      />
    </svg>
  </Styled.Icon>
);

export default YelpLogoIcon;
