import styled from 'styled-components/macro';
import {
  DESKTOP_SCREEN_WIDTH_PX,
  MAX_CONTENT_WIDTH_PX,
  CONTENT_HZ_PADDING_PX,
  MOBILE_CONTENT_HZ_PADDING_PX,
  TABLET_CONTENT_HZ_PADDING_PX,
  TABLET_SCREEN_WIDTH_PX,
} from 'src/constants/layout';

const StyledContentWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding-left: ${MOBILE_CONTENT_HZ_PADDING_PX}px;
  padding-right: ${MOBILE_CONTENT_HZ_PADDING_PX}px;

  @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
    padding-left: ${TABLET_CONTENT_HZ_PADDING_PX}px;
    padding-right: ${TABLET_CONTENT_HZ_PADDING_PX}px;
  }

  @media only screen and (min-width: ${DESKTOP_SCREEN_WIDTH_PX}px) {
    max-width: ${MAX_CONTENT_WIDTH_PX}px;
    padding-left: ${CONTENT_HZ_PADDING_PX}px;
    padding-right: ${CONTENT_HZ_PADDING_PX}px;
  }
`;

export default StyledContentWrapper;
