/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import styled, { createGlobalStyle } from 'styled-components/macro';
// import { useStaticQuery, graphql } from 'gatsby';

import CallNowButton from 'src/components/CallNowButton';
import Navbar from './Navbar';
import Footer from './Footer';

const Styled = {
  Layout: styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
  `,
  ChildrenWrapper: styled.main`
    flex: 1;
    width: 100%;
    position: relative;
  `,
};
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;

interface Props {
  page: string;
  children: any[];
}

const Layout: React.FC<Props> = ({ page, children }) => (
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  <Styled.Layout>
    <GlobalStyle />
    <Navbar page={page} />
    <Styled.ChildrenWrapper>
      {children}
      <CallNowButton page={page} />
    </Styled.ChildrenWrapper>
    <Footer />
  </Styled.Layout>
);
export default Layout;
